/*
 * @TODO: this file is a duplicate. We have another one in the graphql/policies/util folder.
 */
export const isActiveDateRange = (
    start?: string | null,
    end?: string | null
) => {
    const now = Date.now();

    return (
        (!start || now > Date.parse(start)) && (!end || now < Date.parse(end))
    );
};
