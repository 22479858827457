import type { TypePolicies } from '@apollo/client';

import { coveredMemberFields } from './covered-member/fields';
import { sharedOffersFields } from './offers';
import { readProviderOffersByType } from './provider-offers';
import { fieldToLowerCase } from './util';

import {
    DrugFamilyFields,
    cacheRedirects,
    formFields,
    generatedPriceQuoteFields,
    mailOrderProviderFields,
    packageFields,
    patientFields,
    pharmacyProviderFields,
    prescriberFields,
    prescriberOrderFields,
    productFields,
    productsConnectionFields,
    readFullName,
    recommendationsFields,
} from './index';

export const MyPhxTypePolicies: TypePolicies = {
    Query: {
        fields: {
            ...cacheRedirects,
        },
    },
    Coverage: {
        keyFields: ['memberId', 'startDate', 'endDate'],
    },
    Patient: {
        merge: true,
        fields: {
            ...patientFields,
        },
    },
    PrescriberOrderPrescriber: {
        merge: true,
        fields: {
            ...prescriberFields,
        },
    },
    PriceQuote: {
        fields: {
            tags: {
                read: () => [],
            },
        },
    },
    DigitallyReceivedPrescriberOrder: {
        fields: {
            ...prescriberOrderFields,
        },
    },
    ProductsConnection: {
        fields: {
            ...productsConnectionFields,
        },
    },
    ProviderOffersConnection: {
        fields: {
            ...recommendationsFields,
            providerOffersByType: {
                read: readProviderOffersByType,
            },
        },
    },
    GeneratedPriceQuotes: {
        fields: {
            ...generatedPriceQuoteFields,
        },
    },
    Product: {
        fields: {
            ...productFields,
            strengthUnit: {
                read: fieldToLowerCase,
            },
        },
    },
    Name: {
        fields: {
            fullName: { read: readFullName },
        },
    },
    Claim: {
        fields: {
            ...recommendationsFields,
        },
    },
    DrugFamily: {
        fields: {
            ...DrugFamilyFields,
        },
    },
    Form: {
        fields: {
            ...formFields,
        },
    },
    Package: {
        fields: {
            ...packageFields,
        },
    },
    Strength: {
        fields: {
            strengthUnit: {
                read: fieldToLowerCase,
            },
        },
    },
    MailOrderProvider: {
        fields: {
            ...mailOrderProviderFields,
        },
    },
    PharmacyProvider: {
        fields: {
            ...pharmacyProviderFields,
        },
    },
    CashOffer: {
        fields: {
            ...sharedOffersFields,
        },
    },
    InsuranceOffer: {
        fields: {
            ...sharedOffersFields,
        },
    },
    PrescriberOrdersConection: {
        fields: {
            edges: {
                keyArgs: ['first', 'statusFilter', 'showClaimPrescriberOrders'],
                merge: false,
            },
        },
    },
    CoveredMember: {
        fields: {
            ...coveredMemberFields,
        },
    },
};
