import { getFragment } from '../../graphql';
import type { OffersConnectionInfoFragment } from '../../graphql/generated/graphql';

export const getOffersForProvider = (
    offersConnection: OffersConnectionInfoFragment | undefined | null,
    providerId: string
) => {
    if (!offersConnection) {
        return undefined;
    }
    return getFragment(
        offersConnection?.edges.find((x) => {
            const provider = getFragment(getFragment(x.node).provider);

            if (provider.id === providerId) {
                return true;
            }

            if (provider.__typename === 'ChainProvider') {
                return provider.pharmacies.some(
                    (pharmacy) => getFragment(pharmacy).id === providerId
                );
            }

            return false;
        })?.node
    );
};
