import {
    Body1,
    Flex,
    HorizontalDivider,
    IconInfo,
    OutlinedCard,
    Stack,
    Subtitle2,
    Subtitle3,
    TextButton,
    Timeline,
} from '@phx/design-system';
import { useDisclosure } from '@phx/design-system/hooks';
import { useTranslation } from 'react-i18next';

import {
    type PriorAuthorizationInfoFragment,
    PriorAuthorizationStatus,
} from '../../../graphql/generated/graphql';

import { PriorAuthInfoCover } from './PriorAuthInfoCover';
import { getStatusTranslation } from './PriorAuthStatusDescriptions';
import { generateSteps } from './timeline-helpers';

export const PriorAuthTimeline = ({
    priorAuthInfo,
}: {
    priorAuthInfo: PriorAuthorizationInfoFragment;
}) => {
    const { t, i18n } = useTranslation();
    const [opened, { open, close }] = useDisclosure(false);

    const isCancelledStatus =
        priorAuthInfo.status === PriorAuthorizationStatus.Cancelled;
    const cancelledSubtitle = isCancelledStatus ? (
        <Subtitle3 color="error">
            {t('priorAuthorization.timelineCard.PaCancelled')}
        </Subtitle3>
    ) : null;

    const steps = generateSteps(
        priorAuthInfo,
        t,
        i18n.language,
        cancelledSubtitle
    );

    const activeSteps = steps.findIndex(
        (step) => step.title === getStatusTranslation(priorAuthInfo.status)
    );

    const timelineColor = isCancelledStatus ? 'error' : 'info';
    const shouldShowDescription =
        priorAuthInfo.status === PriorAuthorizationStatus.New ||
        priorAuthInfo.status === PriorAuthorizationStatus.Received ||
        priorAuthInfo.status === PriorAuthorizationStatus.InReview;

    return (
        <OutlinedCard>
            <Stack gap="xs">
                <Flex justify="space-between">
                    <Subtitle2>
                        {t('priorAuthorization.timelineCard.heading')}
                    </Subtitle2>
                    <TextButton onClick={open} LeftIcon={IconInfo}>
                        {t('priorAuthorization.timelineCard.textButton')}
                    </TextButton>
                </Flex>
                <HorizontalDivider />
                <Timeline
                    color={timelineColor}
                    steps={steps}
                    activeStep={activeSteps}
                />
                {shouldShowDescription ? (
                    <>
                        <HorizontalDivider />
                        <Body1>
                            {t('priorAuthorization.timelineCard.description')}
                        </Body1>
                    </>
                ) : null}
            </Stack>
            <PriorAuthInfoCover isOpen={opened} onClose={close} />
        </OutlinedCard>
    );
};
