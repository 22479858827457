import { Navigate, useNavigate } from 'react-router-dom';

import PlanSummary from '../../components/plan-summary/PlanSummary';
import { getFragment } from '../../graphql';
import {
    type CoverageInfoFragment,
    GetPatientDocument,
} from '../../graphql/generated/graphql';
import { isActiveDateRange } from '../../graphql/policies/util';
import { usePatientContext } from '../../hooks/use-patient-context';
import { QueryLoader } from '../../loaders';
import type { ResultOf } from '../../types';
import { getAbsoluteRoute } from '../../util';

const PlanSummaryRoute = () => {
    const Loader = QueryLoader<typeof GetPatientDocument>;
    const { primaryPatientId: patientId } = usePatientContext();

    return (
        <Loader
            query={GetPatientDocument}
            component={DataHandler}
            variables={{ patientId }}
        />
    );
};

type DataHandlerProps = { data: ResultOf<typeof GetPatientDocument> };

const DataHandler = ({ data }: DataHandlerProps) => {
    const navigate = useNavigate();
    const coverageEdges: CoverageInfoFragment[] = [];
    const patient = getFragment(data.patient);
    let planDetailsUrl;
    patient?.coveragesConnection.edges?.forEach((edge) => {
        const coverage = getFragment(edge.coverage);
        if (isActiveDateRange(coverage.startDate, coverage.endDate)) {
            coverageEdges.push(coverage);
            const plan = getFragment(edge.node);
            if (plan.__typename == 'PhxBenefitsPlan' && plan.planDetails) {
                planDetailsUrl = plan.planDetails;
            }
        }
    });

    if (!coverageEdges) {
        return <Navigate to="/" />;
    }

    return (
        <PlanSummary
            coverageEdges={coverageEdges}
            onClaimHistoryClick={() =>
                navigate(getAbsoluteRoute('claims.history'))
            }
            planDetailsUrl={planDetailsUrl}
        />
    );
};

export default PlanSummaryRoute;
