import {
    Chip,
    DatePickerInput,
    Group,
    HorizontalDivider,
    Stack,
    Subtitle1,
    YearPickerInput,
} from '@phx/design-system';
import {
    type ComponentPropsWithoutRef,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import type {
    GetCoverageMembersQuery,
    TimelineFilter,
} from '../../../graphql/generated/graphql';

import { formatDateToTimelineFilter } from './format-date-to-timeline-filter';
import { getLocalDate } from './get-local-date';

export type CoveragePeriod = 'current' | 'calendarYear' | 'custom';

type YearPickerChangeHandler = NonNullable<
    ComponentPropsWithoutRef<typeof YearPickerInput>['onChange']
>;
type DatePickerChangeHandler = NonNullable<
    ComponentPropsWithoutRef<typeof DatePickerInput>['onChange']
>;
type YearPickerValue = Parameters<YearPickerChangeHandler>[0];

export function CoveragePeriod({
    coveragePeriod,
    onChipSelect,
    onChangeDate,
    currentTimeline,
    data,
}: {
    coveragePeriod: CoveragePeriod;
    onChipSelect: (value: CoveragePeriod) => void;
    onChangeDate: (value: TimelineFilter) => void;
    currentTimeline?: TimelineFilter;
    data: NonNullable<
        GetCoverageMembersQuery['patient']
    >['coveragesConnection']['edges'];
}) {
    const today = useMemo(() => new Date(), []);
    const { t } = useTranslation();

    const { minDate, maxDate } = useMemo(() => {
        let earliest = today;
        let latest = today;

        data.forEach((edge) => {
            if (edge.coverage.startDate) {
                const startDate = getLocalDate(edge.coverage.startDate);
                if (startDate < earliest) {
                    earliest = startDate;
                }
            }
            if (edge.coverage.endDate) {
                const endDate = getLocalDate(edge.coverage.endDate);
                if (endDate > latest) {
                    latest = endDate;
                }
            }
        });

        return {
            minDate: earliest,
            maxDate: latest > today ? today : latest,
        };
    }, [data, today]);

    const [yearValue, setYearValue] = useState<YearPickerValue>(() =>
        currentTimeline ? new Date(currentTimeline.startDate) : today
    );

    const [dateValue, setDateValue] = useState<[Date | null, Date | null]>(
        () =>
            currentTimeline
                ? [
                      new Date(currentTimeline.startDate),
                      new Date(currentTimeline.endDate),
                  ]
                : [null, null]
    );

    const handleDateChange: DatePickerChangeHandler = (dates) => {
        setDateValue(dates);

        if (dates[0] && dates[1]) {
            const newRange = {
                startDate: formatDateToTimelineFilter(dates[0]),
                endDate: formatDateToTimelineFilter(dates[1]),
            };
            onChipSelect('custom');
            onChangeDate(newRange);
        }
    };

    useEffect(() => {
        if (!coveragePeriod) return;

        if (coveragePeriod === 'current' && currentTimeline) {
            const start = getLocalDate(currentTimeline.startDate);

            setDateValue([start, today]);
        } else if (coveragePeriod === 'calendarYear') {
            const yearStart = new Date(today.getFullYear(), 0, 1);
            const yearEnd = new Date(today.getFullYear(), 11, 31);

            const adjustedStart = yearStart < minDate ? minDate : yearStart;
            const adjustedEnd = yearEnd > maxDate ? maxDate : yearEnd;

            setYearValue(adjustedStart);
            onChangeDate({
                startDate: formatDateToTimelineFilter(adjustedStart),
                endDate: formatDateToTimelineFilter(adjustedEnd),
            });
        }
    }, [coveragePeriod]);

    const handleYearChange: YearPickerChangeHandler = (value) => {
        if (!value) return;
        setYearValue(value);

        const yearStart = new Date(value.getFullYear(), 0, 1);
        const yearEnd = new Date(value.getFullYear(), 11, 31);

        const adjustedStart = yearStart < minDate ? minDate : yearStart;
        const adjustedEnd = yearEnd > maxDate ? maxDate : yearEnd;

        onChangeDate({
            startDate: formatDateToTimelineFilter(adjustedStart),
            endDate: formatDateToTimelineFilter(adjustedEnd),
        });
    };

    const picker =
        coveragePeriod === 'calendarYear' ? (
            <YearPickerInput
                label="Year"
                maxDate={maxDate}
                minDate={minDate}
                value={yearValue}
                onChange={handleYearChange}
            />
        ) : (
            <DatePickerInput
                label="Date range"
                maxDate={maxDate}
                minDate={minDate}
                value={dateValue}
                onChange={handleDateChange}
            />
        );

    return (
        <Stack>
            <Subtitle1>{t('claimHistory.coveragePeriod.heading')}</Subtitle1>
            <Chip.Group
                onChange={(value) => onChipSelect(value as CoveragePeriod)}
                value={coveragePeriod}
                multiple={false}
            >
                <Group>
                    <Chip value="current">
                        {t('claimHistory.coveragePeriod.current')}
                    </Chip>
                    <Chip value="calendarYear">
                        {t('claimHistory.coveragePeriod.calendarYear')}
                    </Chip>
                    <Chip value="custom">
                        {t('claimHistory.coveragePeriod.custom')}
                    </Chip>
                </Group>
            </Chip.Group>
            {picker}
            <HorizontalDivider variant="full" />
        </Stack>
    );
}
