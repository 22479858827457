import { Stack } from '@phx/design-system';
import { useDebouncedState } from '@phx/design-system/hooks';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createSearchParams, useSearchParams } from 'react-router-dom';

import { DrugSearchBarV2 } from './DrugSearchBarV2';
import { DrugSearchResultsV2 } from './DrugSearchResultsV2';

const autocompleteService = import.meta.env.VITE_AUTOCOMPLETE_URL;

type DrugSearchProps = {
    onDrugSelect: (drugId: string, searchParams?: string) => void;
    onFocus?: () => void;
    shouldAutoFocus?: boolean;
};

type DrugSearchResponse = {
    results: DrugResult[];
};

export type DrugResult = {
    name: string;
    gpidTen: string;
    brandName: string;
    genericName: string;
    displayName: string;
    gpidEights: string[];
    gpidTwelves: string[];
    rank: number;
    id: string;
};

export const DrugSearchV2 = ({
    onFocus,
    onDrugSelect,
    shouldAutoFocus,
}: DrugSearchProps) => {
    const { t } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [loading, setLoading] = useState(false);
    const [results, setResults] = useState<DrugResult[]>([]);
    const paramKey = 'q';

    const [searchInput, setSearchInput] = useDebouncedState(
        searchParams.get(paramKey) ?? '',
        250
    );

    useEffect(() => {
        handleSearch(searchInput);
    }, [searchInput]);

    const isValidInputLength = searchInput.length >= 1;

    const handleSearch = async (searchTerm: string) => {
        try {
            setLoading(true);
            const res = await fetch(
                `${autocompleteService}/search/v2?q=${searchTerm}`,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            const parsed = await res.json();
            const parsedRes = parsed as DrugSearchResponse;
            setResults(parsedRes.results);
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    };

    const onDrugSearch = (input: string) => {
        setSearchParams({ [paramKey]: input }, { replace: true });
        setSearchInput(input);
    };

    // TODO: Define this better for new search in med market
    const handleDrugSelect = (drugId: string, searchParams?: string) => {
        const params = createSearchParams(searchParams);
        params.set('q', searchInput);
        onDrugSelect(drugId, params.toString());
    };
    const showResults = !loading && isValidInputLength;

    return (
        <Stack gap="lg">
            <DrugSearchBarV2
                onFocus={onFocus}
                autoFocus={shouldAutoFocus}
                label={t('drugSearch.searchLabel')}
                queryValue={searchParams.get(paramKey)}
                onChange={onDrugSearch}
            />
            {showResults ? (
                <DrugSearchResultsV2
                    results={results ?? []}
                    searchInput={searchInput}
                    onDrugSelect={handleDrugSelect}
                />
            ) : null}
        </Stack>
    );
};
