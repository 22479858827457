import { AuthorizedOutlet } from '@phx/auth/client';
import { Navigate, Outlet, type RouteObject } from 'react-router-dom';

import { getAbsoluteRoute } from '../../util';
import routes from '../app-routes';

import { ProfileProvider } from '../../contexts/ProfileProvider';
import OrderConfirmationRoute from './OrderConfirmationRoute';
import OrderDetailsRoute from './OrderDetailsRoute';
import PriorAuthorizationRoute from './prior-authorization/PriorAuthorizationRoute';

const ordersRoutes: RouteObject[] = [
    {
        path: routes.orders.root,
        element: <AuthorizedOutlet />,
        children: [
            {
                index: true,
                element: (
                    <Navigate
                        to={getAbsoluteRoute('cabinet.history')}
                        replace
                    />
                ),
            },
            {
                path: routes.orders.prescriberOrderId.root,
                element: (
                    <ProfileProvider>
                        <Outlet />
                    </ProfileProvider>
                ),
                children: [
                    {
                        index: true,
                        /*
                         * In this case, we explicitly want a relative redirect,
                         * hence the direct use of the route instead of
                         * getAbsoluteRoute.
                         */
                        element: (
                            <Navigate
                                to={routes.orders.prescriberOrderId.details}
                                replace
                            />
                        ),
                    },
                    {
                        path: routes.orders.prescriberOrderId.confirmation,
                        element: <OrderConfirmationRoute />,
                    },
                    {
                        path: routes.orders.prescriberOrderId.details,
                        element: <OrderDetailsRoute />,
                    },
                    {
                        path: routes.orders.prescriberOrderId
                            .priorAuthorization,
                        element: <PriorAuthorizationRoute />,
                    },
                ],
            },
        ],
    },
];

export default ordersRoutes;
