import {
    Body1,
    Button,
    Checkbox,
    Heading1,
    Stack,
    Subtitle1,
} from '@phx/design-system';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type {
    GetCoverageMembersQuery,
    TimelineFilter,
} from '../../graphql/generated/graphql';
import { ConditionalComponent } from '../utility/ConditionalComponent';

import { CoveragePeriod } from './coverage-info/CoveragePeriod';
import { formatDateToTimelineFilter } from './coverage-info/format-date-to-timeline-filter';
import { getCurrentBenefitTimeline } from './coverage-info/get-current-benefit-timeline';

type DisplayablePatient = NonNullable<
    NonNullable<
        NonNullable<
            NonNullable<
                NonNullable<
                    GetCoverageMembersQuery['patient']
                >['coveragesConnection']
            >['edges']
        >[number]
    >['coverage']
>['coveredMembers'][number];

export const ClaimHistoryV2 = ({ data }: { data: GetCoverageMembersQuery }) => {
    const { t } = useTranslation();
    const [coveragePeriod, setCoveragePeriod] =
        useState<CoveragePeriod>('current');

    const now = new Date();
    const formattedNow = formatDateToTimelineFilter(now);

    const benefitTimeline = getCurrentBenefitTimeline(
        data.patient?.coveragesConnection.edges ?? []
    ) ?? {
        startDate: formattedNow,
        endDate: formattedNow,
    };
    const [dateRange, setDateRange] = useState<TimelineFilter>(benefitTimeline);
    const [hasClaims, setHasClaims] = useState(false);

    const patientIdSet = new Set<string>();
    data.patient?.coveragesConnection.edges.forEach((patient) =>
        patient.coverage.coveredMembers.forEach((pt) =>
            patientIdSet.add(pt.patient.id)
        )
    );

    const [selectedPatientIds, setSelectedPatientIds] = useState<string[]>(
        Array.from(patientIdSet.values())
    );

    const { displayPatients, hasClaims: currentHasClaims } = useMemo(() => {
        if (!data.patient?.coveragesConnection?.edges)
            return { displayPatients: [], hasClaims: false };

        const allPatientsMap = new Map<string, DisplayablePatient>();
        let hasClaimsInPeriod = false;

        data.patient.coveragesConnection.edges.forEach((edge) => {
            if (!edge?.coverage?.startDate || !edge?.coverage?.endDate) {
                return undefined;
            }
            if (
                edge.coverage.startDate > dateRange.endDate ||
                edge.coverage.endDate < dateRange.startDate
            ) {
                return undefined;
            }

            edge.coverage.coveredMembers?.forEach((member) => {
                if (member?.patient) {
                    const { id } = member.patient;
                    allPatientsMap.set(id, member);

                    if (selectedPatientIds.includes(id)) {
                        const hasClaimsInRange = member.claims?.some(
                            (claim) => {
                                const claimDate = formatDateToTimelineFilter(
                                    new Date(claim.dateProcessed)
                                );
                                return (
                                    claimDate >= dateRange.startDate &&
                                    claimDate <= dateRange.endDate
                                );
                            }
                        );
                        if (hasClaimsInRange) {
                            hasClaimsInPeriod = true;
                        }
                    }
                }
            });
        });

        return {
            displayPatients: Array.from(allPatientsMap.values()),
            hasClaims: hasClaimsInPeriod,
        };
    }, [data, dateRange, selectedPatientIds]);

    useEffect(() => {
        setHasClaims(currentHasClaims);
    }, [currentHasClaims]);

    const hasValidCoverage = displayPatients.length > 0;

    const allMembersState = useMemo(() => {
        const allPatientIds = displayPatients.map((pt) => pt.patient.id);
        const isAllSelected = allPatientIds.every((id) =>
            selectedPatientIds.includes(id)
        );
        const isSomeSelected = allPatientIds.some((id) =>
            selectedPatientIds.includes(id)
        );
        return {
            checked: isAllSelected,
            indeterminate: !isAllSelected && isSomeSelected,
        };
    }, [displayPatients, selectedPatientIds]);

    const handleCoveragePeriodChange = (value: CoveragePeriod) => {
        setCoveragePeriod(value);
        let newRange: TimelineFilter;
        switch (value) {
            case 'current':
                newRange = benefitTimeline;
                break;
            case 'calendarYear':
                newRange = {
                    startDate: formatDateToTimelineFilter(
                        new Date(now.getFullYear(), 0, 1)
                    ),
                    endDate: formattedNow,
                };
                break;
            case 'custom':
                newRange = dateRange;
                break;
        }
        setDateRange(newRange);
    };

    const handleDateRangeChange = (newRange: TimelineFilter) => {
        setDateRange(newRange);
    };

    const handleAllPatientsSelection = () => {
        if (allMembersState.checked || allMembersState.indeterminate) {
            setSelectedPatientIds([]);
        } else {
            setSelectedPatientIds(displayPatients.map((pt) => pt.patient.id));
        }
    };

    return (
        <>
            <Stack mb="lg">
                <Heading1>{t('claimHistory.heading')}</Heading1>
            </Stack>
            <CoveragePeriod
                onChipSelect={handleCoveragePeriodChange}
                coveragePeriod={coveragePeriod}
                onChangeDate={handleDateRangeChange}
                currentTimeline={dateRange}
                data={data.patient?.coveragesConnection.edges ?? []}
            />
            <Stack mt="lg">
                {hasValidCoverage ? (
                    <>
                        <Subtitle1>
                            {t('claimHistory.coveragePeriod.heading')}
                        </Subtitle1>
                        <Stack>
                            <Checkbox
                                label={t('claimHistory.planMembers')}
                                onChange={handleAllPatientsSelection}
                                checked={allMembersState.checked}
                                indeterminate={allMembersState.indeterminate}
                            />
                            <Checkbox.Group
                                value={selectedPatientIds}
                                onChange={setSelectedPatientIds}
                            >
                                <Stack ml="md">
                                    {displayPatients.map((pt) => (
                                        <Checkbox
                                            key={pt.patient.id}
                                            value={pt.patient.id}
                                            label={`${pt.patient.firstName} ${pt.patient.lastName} • ${pt.relationship.relationshipType}`}
                                        />
                                    ))}
                                </Stack>
                            </Checkbox.Group>
                        </Stack>
                    </>
                ) : (
                    <Body1>{t('claimHistory.noCoverage')}</Body1>
                )}
            </Stack>

            <Stack mt="auto">
                <ConditionalComponent condition={!hasClaims}>
                    <Body1>{t('claimHistory.noClaims.selection')}</Body1>
                </ConditionalComponent>
                <Button mt="auto" disabled={!hasValidCoverage || !hasClaims}>
                    {t('claimHistory.button.download')}
                </Button>
            </Stack>
        </>
    );
};
