import {
    Anchor,
    Box,
    Flex,
    Heading1,
    HorizontalDivider,
    IconOpenInNew,
    IconUpload,
    Modal,
    NavLink,
    Stack,
    TextButton,
} from '@phx/design-system';
import { useDisclosure } from '@phx/design-system/hooks';
import { useTranslation } from 'react-i18next';

import { type CoverageInfoFragment } from '../../graphql/generated/graphql';
import { useFlags } from '../../hooks/use-flags';
import { ConditionalComponent } from '../utility/ConditionalComponent';

import { FamilySummaryCard } from './components/FamilySummaryCard';
import { IndividualSummaryCard } from './components/IndividualSummaryCard';
import { ModalContent } from './components/ModalContent';

export type PlanSummaryProps = {
    planDetailsUrl?: string;
    coverageEdges: CoverageInfoFragment[];
    onClaimHistoryClick: () => void;
};

const PlanSummary = ({
    coverageEdges,
    onClaimHistoryClick,
    planDetailsUrl,
}: PlanSummaryProps) => {
    const { t } = useTranslation();
    const [opened, { open, close }] = useDisclosure(false);
    const { memberClaimHistoryEnabled } = useFlags();

    return (
        <Stack gap="lg">
            <Flex align="center" justify="space-between">
                <Heading1>{t('planSummary.title')}</Heading1>
                <ConditionalComponent condition={!!planDetailsUrl}>
                    <TextButton
                        LeftIcon={IconOpenInNew}
                        color="primary"
                        onClick={() =>
                            //NOTE: Consider this link as an i18n string when the PDF is able to be provided in other languages
                            window.open(planDetailsUrl)
                        }
                    >
                        {t('planSummary.downloadPlanDetails')}
                    </TextButton>
                </ConditionalComponent>
            </Flex>
            <Stack gap="md">
                <IndividualSummaryCard coverages={coverageEdges} />
                <FamilySummaryCard coverages={coverageEdges} />
            </Stack>
            <Box>
                <Anchor onClick={open}>
                    {t('planSummary.learnMoreAnchor')}
                </Anchor>
            </Box>
            <Modal
                title={t('planSummary.aboutModal')}
                onClose={close}
                opened={opened}
            >
                <ModalContent />
            </Modal>
            <HorizontalDivider />
            {memberClaimHistoryEnabled ? (
                <TextButton
                    LeftIcon={IconUpload}
                    onClick={onClaimHistoryClick}
                    justify="flex-start"
                >
                    {t('planSummary.exportClaimHistory')}
                </TextButton>
            ) : (
                <NavLink
                    label={t('planSummary.claimHistory')}
                    onClick={onClaimHistoryClick}
                    variant="subtle"
                    data-testid="plan-summary-claim-history"
                />
            )}
        </Stack>
    );
};

export default PlanSummary;
