import { Box } from '@phx/design-system';
import type { RouteObject } from 'react-router-dom';

import AppRoot from '../components/layout/AppRoot';

import routes from './app-routes';
import authRoutes from './auth';
import cabinetRoutes from './cabinet';
import claimsRoutes from './claims';
import drugRoutes from './drug';
import errorRoutes from './error';
import RootErrorRoute from './error/RootErrorRoute';
import helpCenterRoutes from './help-center';
import homeRoutes from './home';
import languageRoutes from './language';
import { medicationRoutes } from './medication';
import ordersRoutes from './orders';
import pharmacyRoutes from './pharmacy';
import planRoutes from './plan';
import profileRoutes from './profile';
import startSavingRoutes from './start-saving';
import { transferRoutes } from './transfer';

const root: RouteObject = {
    path: routes.root,
    element: <AppRoot />,
    errorElement: (
        <Box p="md">
            <RootErrorRoute />
        </Box>
    ),
    children: [
        ...authRoutes,
        ...cabinetRoutes,
        ...claimsRoutes,
        ...drugRoutes,
        ...errorRoutes,
        ...homeRoutes,
        ...ordersRoutes,
        ...languageRoutes,
        ...pharmacyRoutes,
        ...planRoutes,
        ...profileRoutes,
        ...startSavingRoutes,
        ...transferRoutes,
        ...helpCenterRoutes,
        ...medicationRoutes,
    ],
};

export default root;
