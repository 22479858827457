import type {
    GetCoverageMembersQuery,
    TimelineFilter,
} from '../../../graphql/generated/graphql';

export function getCurrentBenefitTimeline(
    data: NonNullable<
        GetCoverageMembersQuery['patient']
    >['coveragesConnection']['edges']
): TimelineFilter | undefined {
    const today = new Date().toISOString().split('T')[0];

    const currentCoverage = data.find(({ coverage }) => {
        if (!coverage.startDate) return false;
        return (
            coverage.startDate <= today &&
            (!coverage.endDate || coverage.endDate >= today)
        );
    });

    return currentCoverage?.coverage.startDate
        ? {
              startDate: currentCoverage.coverage.startDate,
              endDate: currentCoverage.coverage.endDate || today,
          }
        : undefined;
}
