import { formatDate } from '@phx/design-system/framework';

export function formatDateToTimelineFilter(date: Date | number) {
    const formatted = formatDate(
        date,
        {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        },
        'en-US'
    )!;

    const [month, day, year] = formatted.split('/');
    return `${year}-${month}-${day}`;
}
