/**
 * Note: We need a better way (Temporal when?) to handle this date checking. The
 * way the function is currently structured, if a date occurs between March and
 * November (when the East coast is GMT-4), we may wind up displaying things in
 * the app an hour later than intended or not showing them until an hour later
 * than desired.
 */
export const isActiveDateRange = (
    start?: string | null,
    end?: string | null,
    inclusive?: boolean
) => {
    const today = Date.parse(new Date().toLocaleDateString('en-US'));

    let fullStart = start;
    let fullEnd = end;

    if (isPlainDateString(start)) {
        fullStart = `${start}T00:00:00.000-05:00`;
    }

    if (isPlainDateString(end)) {
        fullEnd = `${end}T23:59:59.999-05:00`;
    }

    if (inclusive) {
        return (
            (!fullStart || today >= Date.parse(fullStart)) &&
            (!fullEnd || today <= Date.parse(fullEnd))
        );
    }

    return (
        (!fullStart || today > Date.parse(fullStart)) &&
        (!fullEnd || today < Date.parse(fullEnd))
    );
};

export const isPlainDateString = (date?: string | null) => {
    return /^\d{4}-\d{2}-\d{2}$/.test(date ?? '');
};
