/**
 * This method is used for parsing date strings into local date. This is
 * needed because parsing date string into Date type will convert it to UTC
 * which will be the incorrect offset for the user.
 *
 * @param dateString Date string in YYYY-MM-DD format
 * @returns Date
 */
export function getLocalDate(dateString: string): Date {
    const [year, month, day] = dateString.split('-').map(Number);
    return new Date(year, month - 1, day);
}
