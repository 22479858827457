import {
    Anchor,
    Body1,
    Box,
    Flex,
    IconFileOpen,
    Stack,
    Subtitle2,
    TextButton,
} from '@phx/design-system';
import { useDisclosure } from '@phx/design-system/hooks';
import { Trans, useTranslation } from 'react-i18next';

import externalRoutes from '../../../../external-routes';
import { PriorAuthorizationStatus } from '../../../../graphql/generated/graphql';
import { DecisionDetailsModal } from '../DecisionDetailsModal';
import { getStatusTranslation } from '../PriorAuthStatusDescriptions';

export const DeniedDescription = ({
    openDecisionLetter,
    determiner,
    rationale,
    trackingNumber,
    appealBeforeDate,
}: {
    openDecisionLetter?: () => Window | null;
    determiner?: string;
    rationale?: string | null;
    trackingNumber?: string | null;
    appealBeforeDate?: string | null;
}) => {
    const { t } = useTranslation();
    const [opened, { toggle }] = useDisclosure();

    return (
        <Stack gap="sm">
            <Body1>
                {t('priorAuthorization.statusPage.denied.description1')}
            </Body1>
            <Box>
                <TextButton onClick={toggle} LeftIcon={IconFileOpen}>
                    {t('priorAuthorization.statusPage.viewDecisionDetails')}
                </TextButton>
            </Box>
            <DecisionDetailsModal
                isOpen={opened}
                toggle={toggle}
                priorAuthStatus={getStatusTranslation(
                    PriorAuthorizationStatus.Denied
                )}
                tracking={trackingNumber}
                rationale={rationale}
                onOpenDecisionLetter={openDecisionLetter}
            />
            <Body1>
                {t('priorAuthorization.statusPage.denied.description2', {
                    determiner,
                })}
            </Body1>

            <Flex gap="xxxs">
                <Subtitle2>
                    {t('priorAuthorization.statusPage.denied.stepOne.one')}
                </Subtitle2>
                <Box>
                    <Subtitle2>
                        {t(
                            'priorAuthorization.statusPage.denied.stepOne.subtitle'
                        )}
                    </Subtitle2>
                    <Body1>
                        {trackingNumber
                            ? t(
                                  'priorAuthorization.statusPage.denied.stepOne.description',
                                  { trackingNumber }
                              )
                            : t(
                                  'priorAuthorization.statusPage.denied.stepOne.descriptionWithoutTracking'
                              )}
                    </Body1>
                </Box>
            </Flex>
            <Flex gap="xxxs">
                <Subtitle2>
                    {t('priorAuthorization.statusPage.denied.stepTwo.two')}
                </Subtitle2>
                <Stack gap="xxs">
                    <Box>
                        <Subtitle2>
                            {t(
                                'priorAuthorization.statusPage.denied.stepTwo.subtitle'
                            )}
                        </Subtitle2>
                        <Body1>
                            {appealBeforeDate ? (
                                <Trans
                                    i18nKey="priorAuthorization.statusPage.denied.stepTwo.description"
                                    values={{ validToDate: appealBeforeDate }}
                                    components={{
                                        1: (
                                            <Anchor
                                                target="_blank"
                                                href={
                                                    externalRoutes.appealRequestForm
                                                }
                                            />
                                        ),
                                    }}
                                />
                            ) : (
                                <Trans
                                    i18nKey="priorAuthorization.statusPage.denied.stepTwo.descriptionWithoutAppealDeadline"
                                    components={{
                                        1: (
                                            <Anchor
                                                target="_blank"
                                                href={
                                                    externalRoutes.appealRequestForm
                                                }
                                            />
                                        ),
                                    }}
                                />
                            )}
                        </Body1>
                    </Box>
                    <Body1>
                        {t('priorAuthorization.statusPage.denied.stepTwo.fax')}
                    </Body1>
                </Stack>
            </Flex>
            <Flex gap="xxxs">
                <Subtitle2>
                    {t('priorAuthorization.statusPage.denied.stepThree.three')}
                </Subtitle2>
                <Box>
                    <Subtitle2>
                        {t(
                            'priorAuthorization.statusPage.denied.stepThree.subtitle'
                        )}
                    </Subtitle2>
                    <Body1>
                        {t(
                            'priorAuthorization.statusPage.denied.stepThree.description'
                        )}
                    </Body1>
                </Box>
            </Flex>
        </Stack>
    );
};
