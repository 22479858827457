import type { FieldReadFunction } from '@apollo/client';
import { t } from 'i18next';

export const readRelationship: FieldReadFunction = (existing) => {
    if (!existing?.relationshipType) return existing;

    const mappings = {
        self: t('member.relationship.self'),
        spouse: t('member.relationship.spouse'),
        primary: t('member.relationship.primary'),
        default: t('member.relationship.dependent'),
    };

    return {
        ...existing,
        relationshipType:
            mappings[existing.relationshipType as keyof typeof mappings] ||
            mappings.default,
    };
};
