import { useAuthContext } from '@phx/auth/client';
import { useNavBar } from '@phx/design-system';
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { DrugSearchV2 } from '../../components/drug-search-v2/DrugSearchV2';
import { getAbsoluteRoute } from '../../util';

export const DrugSearchRouteV2 = () => {
    const { setBackOverrideState } = useNavBar();
    const { isAuthenticated } = useAuthContext();

    const navigate = useNavigate();
    const onDrugSelect = async (familyId: string, searchParams?: string) => {
        const path = getAbsoluteRoute('medication.familyId.root', { familyId });
        navigate({
            pathname: path,
            search: searchParams ?? '',
        });
    };

    const handleBack = useCallback(() => {
        if (isAuthenticated) {
            navigate(getAbsoluteRoute('home'));
        } else {
            navigate(getAbsoluteRoute('root'));
        }
    }, [isAuthenticated, navigate]);

    useEffect(() => {
        setBackOverrideState({
            overrideFn: handleBack,
        });

        return () => setBackOverrideState({ overrideFn: null });
    }, [handleBack]);

    return <DrugSearchV2 shouldAutoFocus onDrugSelect={onDrugSelect} />;
};
