import {
    DividedText,
    Flex,
    Stack,
    Subtitle1,
    useConditionalBackOverride,
} from '@phx/design-system';
import { useTelemetryContext } from '@phx/instrumentation/react';
import {
    Navigate,
    createSearchParams,
    useNavigate,
    useParams,
    useSearchParams,
} from 'react-router-dom';

import { getFragment } from '../../graphql';
import {
    MedicationFamilyDocument,
    type MedicationFamilyQuery,
} from '../../graphql/generated/graphql';
import { useFlags } from '../../hooks/use-flags';
import { type DataHandlerType, QueryLoader } from '../../loaders';
import { getAbsoluteRoute } from '../../util';

export const MedicationRoute = () => {
    const { typeaheadSearchEnabled } = useFlags();
    if (!typeaheadSearchEnabled) {
        return <Navigate to={getAbsoluteRoute('drug.search.root')} />;
    }

    return <RenderMedicationRoute />;
};
const RenderMedicationRoute = () => {
    const Loader = QueryLoader<typeof MedicationFamilyDocument>;
    const { familyId } = useParams();

    if (!familyId) {
        return <Navigate to={getAbsoluteRoute('error')} />;
    }

    return (
        <Loader
            query={MedicationFamilyDocument}
            variables={{
                id: familyId,
            }}
            queryOptions={{ errorPolicy: 'all' }}
            component={DataHandler}
        />
    );
};

const DataHandler: DataHandlerType<typeof MedicationFamilyDocument> = ({
    data,
}: {
    data: MedicationFamilyQuery;
}) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { familyId } = useParams();
    const searchQuery = searchParams.get('q');
    const { telemetryInstance } = useTelemetryContext();

    const handleBack = () => {
        if (searchQuery) {
            navigate({
                pathname: getAbsoluteRoute('drug.search.root'),
                search: createSearchParams({ q: searchQuery }).toString(),
            });
        } else {
            navigate(getAbsoluteRoute('drug.search.root'));
        }
    };

    useConditionalBackOverride(handleBack, !!searchQuery);
    const medicationFamilyInfo = getFragment(data.medicationFamilyV2);

    if (!medicationFamilyInfo) {
        telemetryInstance.logError({
            source: 'medication-route',
            error: new Error(`no data for medicationFamilyId ${familyId}`),
        });
        return <Navigate to={getAbsoluteRoute('error')} />;
    }

    //NOTE: all of this logic will be moved to a type policy.
    const formFragments = medicationFamilyInfo.forms.map((form) =>
        getFragment(form)
    );

    const defaultBrandMedication = getFragment(
        medicationFamilyInfo.defaultBrandMedication
    );
    const defaultGenericMedication = getFragment(
        medicationFamilyInfo.defaultGenericMedication
    );
    const defaultMedication = getFragment(
        medicationFamilyInfo.defaultBrandMedication
    );
    return (
        <Stack gap="xl">
            <Stack>
                <Subtitle1 data-testid="search-product-details-header">
                    {medicationFamilyInfo.name}
                </Subtitle1>
                <Flex>
                    Brand names:&nbsp;
                    <DividedText entries={medicationFamilyInfo.brandNames} />
                </Flex>
                <Flex>
                    Generic names:&nbsp;
                    <DividedText entries={medicationFamilyInfo.genericNames} />
                </Flex>
                {defaultMedication && (
                    <Flex>
                        defaultMedication:&nbsp;
                        <DividedText
                            entries={[
                                defaultMedication.name,
                                defaultMedication.id,
                                defaultMedication.formCode,
                                `${defaultMedication.strengthValue}${defaultMedication.strengthUnit}`,
                            ]}
                        />
                    </Flex>
                )}
                {defaultBrandMedication && (
                    <Flex>
                        defaultBrandMedication:&nbsp;
                        <DividedText
                            entries={[
                                defaultBrandMedication.name,
                                defaultBrandMedication.id,
                                defaultBrandMedication.formCode,
                                `${defaultBrandMedication.strengthValue}${defaultBrandMedication.strengthUnit}`,
                            ]}
                        />
                    </Flex>
                )}
                {defaultGenericMedication && (
                    <Flex>
                        defaultGenericMedication:&nbsp;
                        <DividedText
                            entries={[
                                defaultGenericMedication.name,
                                defaultGenericMedication.id,
                                defaultGenericMedication.formCode,
                                `${defaultGenericMedication.strengthValue}${defaultGenericMedication.strengthUnit}`,
                            ]}
                        />
                    </Flex>
                )}
            </Stack>

            {formFragments.map((form, index) => {
                return (
                    <Stack key={`${form.formCode}${index}`}>
                        <Subtitle1>{form.formCode}</Subtitle1>
                        <Stack ml={8} gap="lg">
                            {form.strengths.map((strength, index) => {
                                const strengthInfo = getFragment(strength);
                                return (
                                    <Flex key={`${index}str`}>
                                        {`${strengthInfo.strengthValue} ${strengthInfo.strengthUnit}`}
                                        <Stack>
                                            {strengthInfo.packages.map(
                                                (medPackage, index) => {
                                                    const packageInfo =
                                                        getFragment(medPackage);

                                                    return (
                                                        <Flex
                                                            ml="md"
                                                            gap="md"
                                                            key={`pkg-${index}-${packageInfo.id}`}
                                                        >
                                                            <Flex>
                                                                {`${packageInfo.id}`}
                                                            </Flex>
                                                            <Flex>{`${packageInfo.packageCode}, default quantity: ${packageInfo.defaultQuantity}, default days supply: ${packageInfo.defaultDaysSupply}`}</Flex>
                                                        </Flex>
                                                    );
                                                }
                                            )}
                                        </Stack>
                                    </Flex>
                                );
                            })}
                        </Stack>
                    </Stack>
                );
            })}
        </Stack>
    );
};
